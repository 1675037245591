$anzco-brand-white: white;
$anzco-brand-black: black;
$anzco-brand-sand: #D6D8BD;
$anzco-brand-soil: #49372C;
$anzco-brand-moss: #90C684;
$anzco-brand-meadow: #B8CF46;
$anzco-brand-grass: #6E962A;
$anzco-brand-pine: #006137;
$anzco-brand-green: #64A70B;
$anzco-brand-blue: #003D4C;
$anzco-brand-ocean: #00587B;
$anzco-brand-river: #219DC3;
$anzco-brand-sky: #B2DCD9;

$anzco-internal-brand-back-each-other: $anzco-brand-river;
$anzco-internal-brand-make-every-day-count: #F39325;
$anzco-internal-brand-play-the-long-game: $anzco-brand-moss;
$anzco-internal-brand-be-a-pioneer: #A777B2;
$anzco-internal-brand-keep-our-promises: $anzco-brand-ocean;

$anzco-brand-lime-green: $anzco-brand-green;
$anzco-brand-dark-green: $anzco-brand-blue;

$anzco-app-background: #F7F8F8;
$anzco-app-background-white: #FEFEFE;
$anzco-app-background-grey: #F7F8F8;
$anzco-notification: #FF3B30;
$anzco-disbaled: #CCCCCC;
$anzco-error: #FF3B30;
$anzco-warning: orange;
$anzco-reject: #B3170E;

$anzco-table-background: #e2edf0;
$anzco-table-even-row: #eff5f7;
$anzco-table-odd-row: $anzco-app-background-white;

$anzco-advice-unknown: #898989;
$anzco-advice-created: #669AAF;
$anzco-advice-processing: #26708E;
$anzco-advice-proposed-pricing: #00587B;
$anzco-advice-invoiced: $anzco-brand-lime-green;

$anzco-semi-transparent-white: rgba(255, 255, 255, 0.8);
$anzco-semi-transparent-aqua: rgba(0, 61, 76, 0.5);

$anzco-disabled-spinner: #E5E4E2;