/**
* Initially generated theme by Material Theme Generator but since been tuned based on the available Qbrand styles
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzAwM2Q0YyIsIj9lcjwjYjNjNWM5IiwiO2VyPCMwMDI3MzN$LCIlPmBePCM2NGE3MGIiLCI~ZXI8I2QxZTViNiIsIjtlcjwjNDg4YzA2fiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00IiwiZmFtaWx5PFBvcHBpbnMiLCJ2YXJpYW50PHJlZ3VsYXJ$LGBAPCgtMyIsImZhbWlseTxQb3BwaW5zfixgQDwoLTIiLCJmYW1pbHk8UG9wcGlucyIsInZhcmlhbnQ8bWVkaXVtfixgQDwoLTEiLCJmYW1pbHk8UG9wcGlucyIsInZhcmlhbnQ8bWVkaXVtIiwibGluZUhlaWdodD40Niwic2l6ZT4zMCwic3BhY2luZz4wfSxgQDxoZWFkbGluZSIsImZhbWlseTxQb3BwaW5zfixgQDx0aXRsZSIsImZhbWlseTxQb3BwaW5zfixgQDxzdWIpLTIiLCJmYW1pbHk8UG9wcGluc34sYEA8c3ViKS0xIiwiZmFtaWx5PFBvcHBpbnN$LGBAPGJvZHktMiIsImZhbWlseTxQb3BwaW5zfixgQDxib2R5LTEiLCJmYW1pbHk8UG9wcGluc34sYEA8YnV0dG9uIiwiZmFtaWx5PFBvcHBpbnN$LGBAPGNhcHRpb24iLCJmYW1pbHk8UG9wcGluc34sYEA8aW5wdXQiLCJmYW1pbHk8UG9wcGlucyIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEyfQ==
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500');
     

// see https://material.angular.io/guide/typography#define-a-level
$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 500, 'Poppins', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 500, 'Poppins', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 600, 'Poppins', 0.0000em),
  display-1: mat.define-typography-level(30px, 46px, 600, 'Poppins', 0.0000em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Poppins', 0.0000em),
  title: mat.define-typography-level(28px, 42px, 700, 'Poppins', 0.0075em),
  subheading-2: mat.define-typography-level($font-size:23px, $font-weight:bold, $font-family:Poppins, $letter-spacing:0.0094em),
  subheading-1: mat.define-typography-level($font-size:18px, $font-weight:bold, $font-family:Poppins, $letter-spacing:0.0094em),
  body-2: mat.define-typography-level($font-size:16px, $font-weight:500,  $line-height:32px, $font-family:'Poppins', $letter-spacing:0.0179em),
  body-1: mat.define-typography-level($font-size:16px, $font-weight:normal, $line-height:32px, $font-family:'Poppins', $letter-spacing:0.0179em),
  button: mat.define-typography-level(14px, 21px, 500, 'Poppins', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Poppins', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Poppins', 1.5px)
);

@import './app//styles/colors.scss';

//TODO utilise these scss variables through out
$primary-color: $anzco-brand-dark-green; 
$primary-lighter-color: #b3c5c9;
$primary-darker-color: #002733;

$accent-color: $anzco-brand-lime-green;
$accent-lighter-color: #d1e5b6;
$accent-darker-color: #488c06;

$warn-color: #ff0000;


// Foreground Elements

// Light Theme Text
$dark-text: $primary-color;
$dark-primary-text: rgba($dark-text, 1);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:    $primary-darker-color;
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Compute font config
@include mat.core($fontConfig);

// Theme Config

body {
  --primary-color: $primary-color;
  --primary-lighter-color: $primary-lighter-color;
  --primary-darker-color: $primary-darker-color;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}   
$mat-primary: (
  main: $primary-color,
  lighter: $primary-lighter-color,
  darker: $primary-darker-color,
  200: $primary-color, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);


body {
  --accent-color: $accent-color;
  --accent-lighter-color: $accent-lighter-color;
  --accent-darker-color: $accent-darker-color;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}   
$mat-accent: (
  main: $accent-color,
  lighter: $accent-lighter-color,
  darker: $accent-darker-color,
  200: $accent-color, // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);


body {
  --warn-color: $warn-color;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: $warn-color;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}   
$mat-warn: (
  main: $warn-color,
  lighter: #ffb3b3,
  darker: $warn-color,
  200: $warn-color, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
;

$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);

// Theme Init
@include mat.all-component-themes($theme);


// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Poppins';
  }
}

//default input colors
.mat-form-field-appearance-fill  .mat-form-field-flex {
    background: white;   
    border: 1px solid #E0E0E0;
}

.anzco {
  .mat-form-field-underline::before {
    background-color: #e0e0e0;
  }
}


.mat-form-field-invalid {
    .mat-form-field-flex {
        border-color: $warn-color;
    }
}

// buttons
.mat-raised-button.mat-accent, .mat-flat-button.mat-accent,  .mat-raised-button.primary, .mat-flat-button.primary { 
    color: white;
    text-transform: uppercase;
}

.mat-calendar-table {
  tr{
    line-height: normal;
  }
  
}