@import "./colors.scss";

@mixin non-scrollable-parent {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  min-height: 100%;
}

@mixin desktop-screen-width {
  width: 1200px;
  max-width: 100%;
}

@mixin main-feature-page {
  justify-content: center;
  display: flex;
  width: 100%;
}

@mixin button {
  height: 36px;
  line-height: inherit;
  background-color: $anzco-brand-dark-green;
  color: $anzco-brand-white;
  cursor: pointer;
}

@mixin input {
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;
  padding: 0px 10px;
  background: $anzco-brand-white;
  border: 1px solid #e0e0e0;
  height: 34px;
}

@mixin headline {
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  h2 {
    display: flex;
  }

  button {
    @include button;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: 1200px) {

    h2 {
      font: 700 20px / 25px Poppins;
    }

    h3 {
      font: 700 15px / 20px Poppins;
    }
  }
}

@mixin white-background-section {
  display: flex;
  background-color: $anzco-app-background-white;
}

@mixin grey-background-section {
  display: flex;
  background-color: $anzco-app-background-grey;
}

@mixin validation-failure {
  color: $anzco-error;
  border-color: $anzco-error;
}

@mixin data-item-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;

  mat-select {
    width: initial;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    padding: 0px 10px;
    background: white;
    border: 1px solid #e0e0e0;
  }

  input {
    @include input;
  }

  mat-select {
    min-width: 150px;
    height: 34px;
  }

  mat-datepicker-toggle {
    height: 36px;
    margin-top: -3px;
    justify-self: end;
  }

  .date-picker div {
    display: grid;

    > * {
      grid-row: 1;
      grid-column: 1;
    }
  }

  .labeled-data-item {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 0 1 auto;
    align-self: end;

    mat-label {
      justify-content: space-between;
      display: flex;
      font-weight: 500;
      font-size: 14px;
    }

    &.drop-down {
      flex-grow: 1;

      .mat-select-panel {
        display: flex;
        flex-direction: column;
      }

      @media only screen and (max-width: 900px) {
        flex: 1 1 100%;
        max-width: none;
      }
    }
    
    .validation-failure {
      @include validation-failure();
    }
  }

  .full-width {
    width: 100%;
  }
}

.icon-button {
  width: 50px;
  max-width: 50px;
  display: block;
}
