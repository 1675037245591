/* You can add global styles to this file, and also import other style files */
@use "theme";

@import "./app/styles/colors.scss";
@import "./app/styles/common.scss";

html,
body {
  height: 100%;
}

body {
  @include non-scrollable-parent;
  margin: 0;

  app-root {
    @include non-scrollable-parent;
  }
}

a {
  color: theme.$dark-primary-text;
}

mat-form-field {
  flex: 1 1 280px;
}

mat-paginator {
  mat-form-field {
    flex: 0 1 auto;
  }
}

mat-card {
  border-radius: 0px !important;
}

mat-expansion-panel {
  border-radius: 0px !important;
}

mat-card.default-card {
  background-color: $anzco-semi-transparent-white;
  padding: 40px;
  margin: 0 auto;

  mat-card-actions {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.grey-spinner circle{
  stroke: $anzco-disabled-spinner !important;
}

.default-container {
  width: 100%;
  margin:  20px auto 20px auto;
}

button.mat-menu-item {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  height: 36px;
  line-height: 36px;
}

@media only screen and (max-width: 1200px) {
  .default-container {
    width: 95%;
    margin:  10px auto 10px auto;
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.no-text-wrap {
  white-space: nowrap;
}

.text-wrap {
  white-space: normal;
}

// conditionally remove html 5 helper up/down arrows
input.number-input-plain::-webkit-outer-spin-button,
input.number-input-plain::-webkit-inner-spin-button {
  display: none;
}

input.number-input-plain {
  -moz-appearance: textfield;
}

snack-bar-container.warning-panel {
  background: $anzco-warning;
}

// allows the loader to sit over it's entire parent
.loader-container {
  position: relative;
}
